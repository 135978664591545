/* Common styles for both horizontal and vertical handles */
.panel-resize-handle {
    background-color: rgba(0, 0, 0, 0.1); /* Light background color */
    transition: background-color 0.3s, transform 0.3s; /* Smooth transitions */
  }
  
  /* Horizontal handle */
  .panel-resize-handle-horizontal {
    height: 10px; /* Adjust height for horizontal handle */
    cursor: ns-resize; /* Use ns-resize for horizontal handle */
  }
  
  /* Vertical handle */
  .panel-resize-handle-vertical {
    width: 10px; /* Adjust width for vertical handle */
    cursor: ew-resize; /* Use ew-resize for vertical handle */
  }
  
  /* Add a hover effect */
  .panel-resize-handle:hover {
    background-color: rgba(0, 0, 0, 0.3); /* Darker on hover */
    transform: scale(1.1); /* Slightly enlarge on hover */
  }
  