.code-block {
    box-sizing: border-box; /* 让 border 和 padding 纳入宽度和高度计算 */
    width: 100%;
    height: 100%;
    padding-bottom: 20px;
}

.react-codemirror2 {
    flex: 1 1 auto;
    margin-top: 0;
    height: 100%;
    position: relative;
  }

.CodeMirror {
    height:100% !important;
    position:absolute;
    border-radius: 8px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
} 